import Request from '../../Request';
import * as qs from "qs";

export default class AdminTableUsers extends Request {
    listAction() {
        return this.request('get', 'admin/users');
    }

    toggleUserAction(userId) {
        return this.request('post', `admin/users/${userId}/toggle`);
    }

    toggleUserExportAction(userId) {
        return this.request('post', `admin/users/${userId}/toggleExport`);
    }

    toggleAdminAction(userId) {
        return this.request('post', `admin/users/${userId}/toggleAdmin`);
    }

    listManagersAction() {
        return this.request('get', `admin/users/list/managers`);
    }

    listActionPaginated(page, perPage, search, sortField, sortDir, filters) {
        const queryString = qs.stringify(
            {
                page: page,
                limit: perPage,
                search: search,
                sortBy: sortField,
                sortDir: sortDir,
                filters: filters
            }
        );

        return this.request('get', 'admin/users/paginated?'+queryString, {}, {
            // params: {
            //     page: page,
            //     limit: perPage,
            //     search: search,
            //     sortBy: sortField,
            //     sortDir: sortDir,
            //     filters: filters
            // }
        });
    }
}